@import url("./presentation/styles/fonts/termina.scss");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow: hidden;
}

.__blank {
  flex: 1;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: "none";
  scrollbar-width: "none";
}

.ant-menu {
  background-color: transparent;
  .ant-menu-item {
    color: #ddd;
    font-family: Termina, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;

    background-color: transparent !important;
    display: flex;
    align-items: center;
    gap: 24px;

    padding: 0;

    svg {
      width: 18px;
      height: 18px;
    }

    span {
      margin-inline-start: 0;
    }

    &.ant-menu-item-selected {
      font-weight: 500;
    }
  }
}
