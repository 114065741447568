$font-family-name: "Termina";

$font-path-light: "../../assets/fonts/Termina/TerminaTest-Light.otf";
$font-path-regular: "../../assets/fonts/Termina/TerminaTest-Regular.otf";
$font-path-medium: "../../assets/fonts/Termina/TerminaTest-Medium.otf";
$font-path-bold: "../../assets/fonts/Termina/TerminaTest-Bold.otf";
$font-path-black: "../../assets/fonts/Termina/TerminaTest-Black.otf";

$font-format: "woff";

@font-face {
  font-family: $font-family-name;
  font-weight: 300;
  src: url("#{$font-path-light}") format("#{$font-format}");
}

@font-face {
  font-family: $font-family-name;
  font-weight: 400;
  src: url("#{$font-path-regular}") format("#{$font-format}");
}

@font-face {
  font-family: $font-family-name;
  font-weight: 500;
  src: url("#{$font-path-medium}") format("#{$font-format}");
}

@font-face {
  font-family: $font-family-name;
  font-weight: 700;
  src: url("#{$font-path-bold}") format("#{$font-format}");
}

@font-face {
  font-family: $font-family-name;
  font-weight: 900;
  src: url("#{$font-path-black}") format("#{$font-format}");
}
