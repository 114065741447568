.schedule {
  .ant-picker-cell-disabled {
    &::before {
      content: none;
    }
  }
  .ant-picker-panel {
    border: none;
  }
}
