.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    width: 10px;
    height: 10px;
    display: block;
    background-color: #333;
    border-radius: 50%;
    margin: 0 5px;
    animation: jump 0.6s infinite alternate;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation-delay: calc($i * 0.2s);
      }
    }
  }

  @keyframes jump {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}
